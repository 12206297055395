const words = [
  [2758, 7398, 4369],
  [8140, 5908, 4560],
  [2175, 6757, 4162],
  [6437, 3239, 445],
  [4854, 7304, 5722],
  [2739, 3462, 8238],
  [2791, 1325, 7960],
  [1666, 3648, 3763],
  [2566, 7319, 6376],
  [7838, 5312, 3444],
  [7801, 6416, 1466],
  [344, 1623, 8177],
  [526, 6418, 1682],
  [4160, 7780, 8382],
  [8237, 5647, 618],
  [7793, 2159, 3288],
  [5602, 2399, 2263],
  [8021, 3627, 7325],
  [5641, 8259, 7038],
  [4605, 3983, 5754],
  [7413, 3265, 8091],
  [2618, 1004, 2115],
  [2138, 7388, 1386],
  [7757, 4637, 8372],
  [8404, 6012, 4347],
  [8523, 7321, 4943],
  [2049, 6636, 7975],
  [225, 6368, 4777],
  [6502, 6370, 1935],
  [5967, 15, 7229],
  [7777, 137, 7324],
  [2455, 4431, 273],
  [8340, 436, 1304],
  [6652, 5378, 2830],
  [1938, 8684, 386],
  [1229, 3115, 7060],
  [2085, 8778, 3443],
  [1382, 6024, 3741],
  [3189, 2720, 8068],
  [1391, 7948, 690],
  [7063, 3489, 8242],
  [6054, 7249, 413],
  [3645, 8473, 3269],
  [1934, 1337, 4561],
  [6021, 3359, 8172],
  [3433, 994, 7054],
  [4944, 5666, 3007],
  [7933, 6884, 5885],
  [4473, 480, 7954],
  [6298, 3223, 8057],
  [1213, 7195, 3748],
  [5341, 2534, 3251],
  [6078, 4529, 7784],
  [3386, 6178, 7529],
  [3183, 1005, 3693],
  [1881, 4656, 7361],
  [2632, 7164, 5751],
  [2708, 1225, 8370],
  [6039, 2321, 227],
  [7209, 8589, 4869],
  [5219, 4402, 1532],
  [123, 7152, 5522],
  [7366, 395, 2882],
  [1489, 6733, 7773],
  [4645, 193, 3529],
  [669, 6764, 8221],
  [8495, 1548, 8081],
  [7424, 8380, 5685],
  [4784, 4539, 8452],
  [3033, 2562, 6428],
  [2132, 6047, 6675],
  [5282, 7255, 1661],
  [3798, 2305, 576],
  [8406, 2850, 6903],
  [135, 8674, 3739],
  [6320, 7089, 4238],
  [209, 6768, 5774],
  [7761, 1394, 5865],
  [6246, 7501, 5138],
  [410, 8133, 1190],
  [2805, 1910, 6754],
  [1009, 7341, 8400],
  [4892, 6791, 3070],
  [468, 6766, 3177],
  [1676, 8357, 2270],
  [2648, 3647, 5808],
  [3664, 3065, 7274],
  [7798, 1434, 6893],
  [591, 7936, 7069],
  [290, 5819, 2734],
  [826, 5388, 7533],
  [2032, 2372, 815],
  [6256, 8650, 5518],
  [7847, 175, 1820],
  [2421, 4951, 7371],
  [1995, 5203, 845],
  [7328, 7794, 4591],
  [2493, 2017, 6853],
  [1546, 2855, 662],
  [6802, 2775, 1805],
  [3540, 8011, 555],
  [1045, 269, 2812],
  [6638, 6161, 3580],
  [846, 6609, 3570],
  [5411, 219, 4296],
  [7258, 5622, 2672],
  [2714, 7400, 4218],
  [2843, 1930, 7390],
  [3264, 3923, 4755],
  [7312, 4206, 2721],
  [5257, 7399, 80],
  [7755, 1545, 7112],
  [7085, 5878, 1918],
  [3677, 7986, 7348],
  [8095, 8682, 7492],
  [1476, 8258, 373],
  [4342, 1316, 3762],
  [5776, 8162, 6845],
  [3262, 7931, 1983],
  [8432, 1370, 5134],
  [1132, 6782, 6030],
  [984, 4285, 8438],
  [6471, 1293, 288],
  [1451, 6233, 5283],
  [7322, 804, 2414],
  [4808, 1292, 6062],
  [7522, 977, 5462],
  [8231, 7339, 2540],
  [1727, 7676, 8260],
  [1544, 3734, 4564],
  [3688, 7457, 5075],
  [2339, 6962, 4936],
  [2711, 6761, 11],
  [8271, 5703, 1095],
  [7270, 6344, 1054],
  [962, 7107, 8241],
  [7543, 2146, 4886],
  [483, 4691, 4333],
  [4654, 6282, 7746],
  [7356, 1796, 8811],
  [8501, 5831, 4085],
  [103, 4274, 2740],
  [3766, 7854, 35],
  [7961, 1314, 5229],
  [6650, 4779, 2150],
  [675, 6852, 3886],
  [2061, 1472, 7652],
  [6961, 4496, 3316],
  [2665, 7412, 2261],
  [6157, 8420, 2839],
  [7825, 1346, 32],
  [593, 4263, 7043],
  [3220, 2438, 3412],
  [86, 1679, 5521],
  [1945, 3785, 4199],
  [1238, 4512, 8215],
  [8528, 2601, 206],
  [5193, 1906, 5466],
  [1725, 477, 6588],
  [7810, 8262, 1445],
  [2643, 3399, 4428],
  [8376, 7013, 237],
  [5126, 1704, 476],
  [4272, 2727, 1003],
  [5716, 540, 8170],
  [3201, 813, 3625],
  [5906, 7422, 4942],
  [6324, 2401, 1667],
  [1622, 3234, 7275],
  [2677, 4420, 2330],
  [4588, 2713, 7146],
  [887, 4176, 7802],
  [8201, 7703, 58],
  [3200, 806, 5070],
  [8476, 353, 6629],
  [1631, 8416, 8038],
  [6028, 4374, 8020],
  [3160, 2452, 6820],
  [2705, 5913, 1239],
  [671, 6356, 7007],
  [5475, 1711, 4851],
  [1111, 1883, 6994],
  [1670, 84, 2337],
  [2645, 8701, 842],
  [7401, 4212, 819],
  [5497, 2189, 423],
  [8534, 2704, 295],
  [4663, 5972, 1673],
  [5376, 6516, 4491],
  [7008, 4103, 1362],
  [7520, 1710, 903],
  [4499, 1427, 7097],
  [3536, 1658, 4744],
  [2340, 6775, 363],
  [6372, 8430, 7408],
  [7284, 5712, 7990],
  [6458, 4708, 2854],
  [1699, 2662, 7200],
  [7386, 8565, 6360],
  [6751, 1454, 6349],
  [1697, 621, 5418],
  [156, 3917, 840],
  [8313, 5779, 7310],
  [8841, 8679, 754],
  [2682, 1051, 245],
  [7287, 4934, 7370],
  [3460, 1717, 1965],
  [6104, 3696, 7969],
  [6785, 5143, 21],
  [1633, 5884, 7429],
  [7525, 4460, 1668],
  [1877, 7082, 763],
  [3456, 2679, 2972],
  [8587, 790, 1750],
  [8700, 6219, 7029],
  [1483, 2886, 7407],
  [6477, 2823, 5442],
  [4579, 8759, 7011],
  [5374, 4532, 1719],
  [448, 6850, 4642],
  [1384, 7234, 3969],
  [1317, 6602, 2975],
  [1390, 5797, 7243],
  [3012, 2444, 5843],
  [1121, 5046, 2143],
  [2294, 65, 960],
  [5773, 8496, 6644],
  [1256, 2688, 8609],
  [3257, 7157, 3733],
  [2436, 1407, 6530],
  [2941, 7140, 2729],
  [7342, 664, 5629],
  [7385, 140, 1554],
  [1416, 4416, 8004],
  [1677, 3140, 7511],
  [3862, 2732, 3244],
  [3167, 2195, 6635],
  [4631, 8680, 794],
  [3749, 199, 5434],
  [2023, 3284, 6522],
  [2845, 4527, 1341],
  [975, 7582, 1624],
  [8354, 3793, 3218],
  [3255, 7783, 5719],
  [698, 5500, 6993],
  [5999, 7900, 3274],
  [1235, 2832, 2026],
  [8179, 5608, 6834],
  [7059, 5965, 6391],
  [444, 3082, 3747],
  [4872, 5895, 2370],
  [184, 8102, 2381],
  [3421, 8002, 8574],
  [4323, 7534, 6421],
  [1998, 7754, 2798],
  [3411, 305, 7343],
  [1296, 7340, 8309],
  [8307, 6417, 7330],
  [2792, 822, 3238],
  [6915, 5520, 3290],
  [907, 7110, 8692],
  [6895, 277, 828],
  [3286, 5541, 4585],
  [222, 3303, 6876],
  [4820, 7953, 7364],
  [4370, 3624, 6454],
  [8166, 2514, 6989],
  [223, 6060, 3229],
  [1339, 7055, 4170],
  [1870, 7908, 1672],
  [6365, 800, 1768],
  [4436, 3219, 8586],
  [3108, 1828, 7126],
  [8284, 3447, 943],
  [5037, 6268, 1426],
  [6460, 341, 3626],
  [6056, 8677, 6822],
  [224, 4089, 3414],
  [4775, 306, 7207],
  [6315, 8090, 1182],
  [2504, 1435, 3241],
  [2530, 2441, 6790],
  [6984, 2608, 5961],
  [7411, 4081, 8368],
  [2801, 8235, 607],
  [6271, 4917, 4203],
  [4743, 1290, 291],
  [31, 1444, 2311],
  [2426, 4865, 6770],
  [4526, 2276, 5862],
  [5708, 6830, 226],
  [1829, 3718, 1480],
  [74, 5759, 4981],
  [2726, 7078, 2410],
  [2282, 791, 7426],
  [7241, 3849, 3214],
  [4251, 7333, 2396],
  [7313, 6020, 5468],
  [8554, 3543, 3266],
  [1785, 7547, 4077],
  [5178, 7345, 3419],
  [3071, 6997, 3704],
  [643, 6631, 5508],
  [2591, 7962, 512],
  [7051, 1455, 4995],
  [463, 5105, 6011],
  [228, 4492, 659],
  [4058, 6579, 5982],
  [7298, 1050, 1189],
  [5114, 8093, 7282],
  [5976, 6878, 2193],
  [1920, 5324, 2287],
  [6587, 6156, 3119],
  [7002, 3523, 1818],
  [5211, 8558, 1874],
  [5755, 7065, 4225],
  [2890, 3101, 7431],
  [6041, 1812, 7517],
  [5905, 2619, 1688],
  [1035, 6811, 4633],
  [300, 995, 3397],
  [5353, 8099, 4629],
  [6301, 2158, 6535],
  [5835, 2859, 7443],
  [7560, 1372, 1895],
  [1055, 1380, 3778],
  [2155, 8252, 5594],
  [783, 107, 1715],
  [6630, 6234, 7937],
  [4933, 7592, 7544],
  [1450, 7318, 7974],
  [8375, 8567, 2117],
  [33, 8807, 2620],
  [5725, 3883, 8168],
  [1660, 8333, 831],
  [5822, 4268, 7305],
  [1944, 5180, 6769],
  [6839, 1931, 3890],
  [5559, 6431, 3222],
  [5717, 8032, 5327],
  [2371, 5720, 8263],
  [4273, 6026, 1342],
  [4697, 630, 3619],
  [7942, 4714, 5753],
  [7987, 4311, 6646],
  [4859, 8214, 753],
  [4634, 379, 7279],
  [5304, 8342, 6844],
  [7117, 5635, 6242],
  [8539, 7266, 4404],
  [8033, 2624, 8546],
  [4695, 1685, 8181],
  [7391, 6033, 5579],
  [4068, 8690, 1376],
  [8353, 1309, 1925],
  [1103, 6711, 4228],
  [935, 4713, 2364],
  [1680, 7320, 171],
  [7334, 3584, 8024],
  [3213, 6565, 6447],
  [3109, 2762, 6741],
  [6423, 524, 7057],
  [2641, 6610, 342],
  [8035, 8329, 3289],
  [6005, 5904, 6223],
  [5247, 6052, 8015],
  [4604, 7346, 969],
  [3666, 1174, 5094],
  [2402, 7393, 4949],
  [5344, 5709, 4505],
  [6750, 2473, 8513],
  [610, 6772, 8312],
  [6628, 3665, 8550],
  [7523, 2551, 1220],
  [6155, 7988, 3528],
  [2717, 1664, 6536],
  [6788, 3424, 1267],
  [7518, 8704, 1496],
  [1470, 6980, 6106],
  [1371, 4223, 853],
  [8591, 6753, 3339],
  [1620, 3292, 825],
  [2199, 5398, 7805],
  [531, 1363, 2348],
  [6767, 6172, 5198],
  [5827, 973, 6732],
  [478, 1063, 4406],
  [4988, 6940, 1637],
  [7855, 6829, 2477],
  [948, 5055, 7224],
  [1044, 473, 2329],
  [6373, 196, 1307],
  [7713, 122, 780],
  [2280, 1501, 5407],
  [4855, 8440, 3080],
  [7026, 6160, 7568],
  [604, 8031, 4805],
  [7956, 563, 7372],
  [6573, 7947, 195],
  [7524, 8670, 1460],
]
export default {words}
