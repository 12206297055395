const words: number[][] = [
  [4820, 7793, 2396, 6024],
  [2812, 7838, 395, 5541],
  [1682, 4170, 5685, 2758],
  [3747, 8680, 476, 2195],
  [7055, 1725, 3677, 5466],
  [1386, 8238, 5692, 8679],
  [2117, 2855, 480, 1304],
  [2339, 7898, 6301, 5521],
  [5905, 1390, 3763, 8404],
  [7937, 3012, 826, 2632],
  [2727, 7195, 4206, 1965],
  [341, 4812, 1532, 7050],
  [4775, 6030, 7324, 4460],
  [7647, 373, 6790, 2798],
  [8307, 4779, 815, 6437],
  [3696, 2591, 2444, 1407],
  [222, 1055, 7954, 8589],
  [8382, 7371, 6052, 8242],
  [1296, 6054, 5647, 3627],
  [8263, 1548, 7319, 3265],
  [3647, 4402, 6156, 8284],
  [4416, 3266, 675, 2711],
  [7501, 8172, 2566, 3189],
  [4944, 7975, 7384, 1182],
  [5138, 6244, 2941, 7400],
  [4512, 7274, 2175, 7794],
  [8237, 1820, 7956, 5703],
  [8528, 7339, 1466, 4369],
  [2540, 1918, 4539, 8339],
  [1633, 6820, 3213, 2708],
  [6962, 5722, 3255, 3570],
  [80, 6633, 6233, 763],
  [4347, 2414, 3540, 1317],
  [3460, 960, 6984, 1631],
  [8140, 6766, 8368, 3886],
  [3923, 7777, 7060, 5976],
  [3108, 3917, 8759, 4945],
  [5134, 290, 1489, 819],
  [7825, 2270, 448, 2421],
  [2305, 5146, 5719, 1380],
  [6282, 2721, 4406, 65],
  [3666, 6298, 1699, 8170],
  [5751, 6047, 1545, 6757],
  [1677, 4491, 8177, 6636],
  [7282, 3523, 1238, 8133],
  [8650, 618, 74, 4529],
  [7412, 790, 3289, 2017],
  [1945, 6012, 1620, 2830],
  [4851, 8565, 1239, 3785],
  [4988, 5468, 1004, 7152],
  [6028, 8259, 3625, 963],
  [3890, 227, 5972, 7112],
  [4633, 225, 5105, 1544],
  [8400, 1710, 8452, 4274],
  [4872, 1870, 1213, 8692],
  [1362, 2061, 7008, 363],
  [15, 6852, 1935, 2401],
  [342, 1697, 6471, 5411],
  [5887, 7249, 4656, 7854],
  [4561, 2679, 4591, 7266],
  [5831, 7089, 2263, 3462],
  [1197, 7408, 845, 4374],
  [5899, 8102, 804, 8670],
  [8309, 5374, 306, 7305],
  [8020, 1292, 7057, 8387],
  [4361, 2370, 6368, 671],
  [1717, 4089, 4431, 591],
  [5407, 199, 8590, 7413],
  [103, 1245, 3234, 7186],
  [3624, 6460, 7370, 4323],
  [8375, 7065, 8081, 2340],
  [6062, 6644, 1983, 3177],
  [2845, 1235, 1995, 5180],
  [7424, 2821, 5819, 84],
  [2882, 6638, 7258, 2138],
  [410, 4058, 3844, 1623],
  [7310, 4218, 3664, 1796],
  [3239, 58, 7802, 8258],
  [1225, 8354, 7943, 7361],
  [4755, 4560, 7075, 5776],
  [6830, 3222, 226, 5602],
  [8271, 2608, 7780, 7318],
  [2159, 442, 4943, 3288],
  [6587, 7670, 2662, 4436],
  [8357, 2562, 7241, 3584],
  [690, 2026, 7146, 192],
  [2032, 653, 1655, 7164],
  [2063, 8093, 137, 5070],
  [6360, 2372, 8091, 1095],
  [1189, 4936, 2426, 1658],
  [444, 5862, 3447, 8574],
  [2734, 2438, 5338, 7322],
  [4892, 1021, 1930, 4228],
  [4744, 3529, 7029, 5944],
  [754, 3229, 4605, 7936],
  [5475, 1009, 7325, 2740],
  [2098, 5378, 4212, 7986],
  [4854, 2493, 6878, 5913],
  [3444, 3109, 384, 1103],
  [6650, 540, 8370, 2452],
  [5143, 8057, 7398, 1593],
  [8440, 3688, 344, 5827],
  [4714, 1719, 1363, 2132],
  [6021, 5344, 4777, 8674],
  [1711, 6961, 842, 8473],
  [4527, 2791, 7407, 3456],
  [5257, 7333, 5005, 8214],
  [7544, 846, 3604, 2618],
  [3007, 7933, 7539, 5878],
  [7810, 7069, 8033, 5324],
  [6039, 800, 7652, 6268],
  [8778, 5193, 4995, 5720],
  [7543, 2832, 1998, 4285],
  [1661, 6629, 5327, 1045],
  [2321, 4263, 2146, 6822],
  [6246, 2726, 2436, 7533],
  [8534, 4951, 1190, 3536],
  [8700, 6223, 7243, 8420],
  [6161, 445, 6630, 4934],
  [7478, 4277, 477, 794],
  [4663, 7064, 1670, 7947],
  [5797, 7761, 6320, 175],
  [4496, 3262, 526, 6991],
  [3693, 5388, 7746, 8523],
  [2574, 5641, 7026, 8807],
  [1256, 4342, 7382, 6416],
  [184, 2386, 6853, 977],
  [8024, 6344, 2714, 7391],
  [8068, 2115, 5629, 664],
  [5965, 7038, 969, 2643],
  [643, 85, 6454, 1805],
  [3251, 2626, 6994, 2311],
  [1391, 1949, 1111, 7320],
  [8682, 7341, 2672, 4584],
  [7459, 1874, 2402, 531],
  [7366, 8495, 5754, 3648],
  [5716, 4088, 269, 3071],
  [2850, 3421, 7847, 1220],
  [3704, 6365, 140, 2337],
  [286, 831, 8701, 7287],
  [5341, 6989, 2648, 604],
  [6418, 64, 6788, 1394],
  [7525, 5434, 7801, 2601],
  [7002, 2514, 3798, 1346],
  [8340, 3733, 1372, 3397],
  [2329, 593, 8677, 2815],
  [8038, 2287, 1920, 1416],
  [2330, 8342, 1293, 5094],
  [4510, 32, 8369, 3200],
  [1316, 305, 7063, 4238],
  [7200, 5075, 1309, 2331],
  [2704, 4805, 7328, 3140],
  [555, 8501, 3748, 6753],
  [4499, 7229, 8609, 21],
  [7157, 2441, 1310, 2720],
  [5759, 4564, 5708, 3414],
  [7755, 792, 5518, 8684],
  [8376, 6652, 7953, 6370],
  [2261, 3741, 3969, 1695],
  [5713, 3766, 8313, 7284],
  [7798, 4526, 4085, 5635],
  [2275, 6986, 3580, 5906],
  [1181, 7301, 413, 5594],
  [6903, 6522, 1704, 1944],
  [7007, 903, 3489, 1785],
  [423, 3065, 2871, 1132],
  [1427, 5666, 2288, 7401],
  [6884, 6477, 3412, 4886],
  [7330, 5773, 8539, 8260],
  [7784, 4697, 3734, 5982],
  [4354, 3264, 4654, 8416],
  [8372, 8841, 2839, 6751],
  [3033, 6315, 20, 698],
  [3160, 7676, 5711, 2843],
  [7388, 1003, 4588, 2775],
  [4203, 4859, 662, 5921],
  [6256, 7960, 6041, 8380],
  [4585, 3220, 6178, 2049],
  [1337, 783, 7440, 2762],
  [7770, 3983, 7524, 2692],
  [4933, 2705, 6219, 7773],
  [6839, 5497, 11, 4505],
  [209, 1005, 5283, 7754],
  [1434, 6741, 4162, 4865],
  [5229, 7427, 607, 2729],
  [8587, 7043, 6157, 1342],
  [5304, 2023, 3244, 7054],
  [3433, 483, 6979, 1325],
  [2085, 6530, 3101, 6733],
  [7085, 3113, 5872, 1829],
  [8221, 463, 7209, 6356],
  [7492, 291, 4068, 2147],
  [7340, 7788, 8513, 6349],
  [6940, 195, 5203, 6022],
  [5961, 468, 825, 8110],
  [3411, 4268, 2645, 6242],
  [2746, 1137, 4333, 7457],
  [8703, 4524, 8811, 5622],
  [8179, 3190, 2039, 1727],
  [5077, 8683, 3201, 1768],
  [6543, 8201, 7969, 2677],
  [811, 5808, 4176, 6785],
  [8099, 5500, 2000, 3269],
  [3284, 973, 7312, 2713],
  [6155, 6980, 1455, 156],
  [1480, 4784, 7502, 610],
  [219, 5055, 3749, 7560],
  [1688, 8353, 6759, 3223],
  [7107, 3036, 2792, 6782],
  [7869, 2399, 822, 5037],
  [5865, 4199, 7511, 4942],
  [8432, 273, 1676, 3115],
  [6764, 123, 8640, 3316],
  [6881, 8546, 2682, 135],
  [1546, 8021, 7343, 1819],
  [4634, 6020, 7429, 1906],
  [7542, 5522, 3645, 4949],
  [6421, 3034, 1444, 2665],
  [4160, 975, 5379, 1445],
  [6610, 1938, 5508, 3274],
  [3419, 1050, 8011, 6997],
  [5608, 2854, 3183, 6775],
  [5753, 6767, 7987, 4808],
  [7900, 7356, 1450, 8406],
  [6845, 4708, 6323, 948],
  [6614, 2276, 5282, 840],
  [7547, 295, 6322, 6761],
  [3167, 1054, 4629, 6271],
  [5118, 8215, 2508, 1664],
  [2455, 5908, 8162, 774],
  [3966, 5822, 6893, 2344],
  [5835, 386, 7990, 1881],
  [5126, 237, 6579, 1685],
  [4869, 6227, 6005, 1339],
  [6768, 6372, 4620, 994],
  [6622, 962, 6376, 4579],
  [3619, 6628, 2717, 1679],
  [3739, 5404, 6646, 4492],
  [6609, 3762, 2688, 1341],
  [7255, 8241, 6160, 3070],
  [7431, 5164, 4225, 1934],
  [8476, 2361, 2805, 1772],
  [4637, 3241, 4713, 7275],
  [2504, 1384, 5418, 2199],
  [7045, 1667, 7931, 6463],
  [1315, 5312, 8015, 2890],
  [6754, 4286, 6423, 1063],
  [6993, 713, 5318, 8558],
  [2800, 7313, 7013, 1673],
  [277, 2381, 1077, 7331],
  [8095, 2534, 6458, 5849],
  [3386, 4691, 7529, 1121],
  [2364, 5967, 7304, 887],
  [2975, 1412, 669, 6032],
  [4917, 7078, 5885, 7759],
  [5843, 8389, 7348, 2787],
  [7518, 6431, 3424, 3916],
  [5233, 8704, 6772, 2972],
  [3218, 7603, 7523, 3553],
  [4981, 6802, 2823, 8235],
  [3778, 210, 7110, 1409],
  [7637, 7476, 2739, 4428],
  [7520, 5198, 4672, 1668],
  [196, 7604, 771, 7522],
  [6640, 193, 1229, 1035],
  [5580, 3528, 813, 8262],
  [5548, 7422, 2530, 7248],
  [6025, 353, 7417, 4642],
  [2284, 6931, 2143, 5884],
  [5219, 1472, 5398, 1036],
  [2449, 1622, 6811, 1060],
  [7765, 2885, 6675, 5904],
  [6234, 6026, 5442, 7393],
  [7272, 7714, 3219, 223],
  [1750, 1877, 2625, 6104],
  [8004, 171, 5712, 4855],
  [6391, 108, 4223, 3328],
  [7783, 6106, 1014, 1624],
  [7629, 659, 3621, 387],
  [6516, 3238, 86, 1910],
  [3543, 1382, 4295, 997],
  [4081, 1451, 530, 2427],
  [984, 5211, 3286, 6602],
  [4077, 6635, 7568, 206],
  [6829, 3849, 976, 1476],
  [656, 7434, 8438, 3082],
  [4645, 2189, 1044, 5613],
  [8550, 2150, 524, 4473],
  [1895, 4604, 4103, 6324],
  [8356, 7961, 436, 5520],
  [7686, 5350, 6060, 122],
  [943, 1447, 2282, 5256],
  [7117, 8653, 3410, 8072],
  [6133, 8591, 8761, 5895],
  [2348, 5725, 6813, 4404],
  [7385, 3407, 4370, 8496],
  [1554, 621, 31, 5910],
  [2977, 7757, 6895, 8732],
  [7234, 1533, 6834, 2589],
  [1468, 7764, 5004, 5774],
  [1672, 26, 6588, 3936],
  [1361, 8031, 6428, 7011],
  [7097, 7908, 1426, 7591],
  [924, 1371, 5555, 7962],
  [4251, 7070, 5999, 4743],
  [3450, 7059, 3257, 512],
  [7334, 3883, 5462, 1017],
  [3793, 1470, 2173, 3399],
  [4794, 2573, 5046, 1654],
  [4420, 5511, 8061, 6769],
  [2371, 3303, 7298, 5353],
  [2491, 6011, 6697, 7426],
  [7959, 245, 2586, 1370],
  [6535, 2148, 6915, 3119],
  [7703, 2440, 8586, 7390],
  [8246, 6791, 904, 791],
  [300, 780, 7411, 8514],
  [2410, 7766, 2551, 1483],
  [4910, 1439, 3653, 8231],
  [3080, 4296, 2477, 1028],
  [1733, 5114, 7915, 5247],
  [1883, 3862, 6078, 2685],
  [2294, 228, 8561, 7948],
  [3204, 5376, 806, 7578],
  [7140, 7769, 326, 4833],
  [2619, 6844, 7582, 3290],
  [1027, 288, 7942, 6750],
  [3446, 7386, 2624, 5779],
  [8635, 7399, 2178, 1252],
  [1501, 7079, 6214, 1022],
  [7270, 5897, 7811, 6079],
  [7003, 390, 5231, 5579],
  [5709, 7540, 1680, 907],
  [6732, 8552, 1496, 2501],
  [8567, 7853, 2535, 6740],
  [8032, 3359, 7342, 2600],
  [8662, 1460, 6033, 107],
  [1625, 6575, 2183, 4273],
  [4903, 6770, 224, 7980],
  [2459, 5356, 6876, 1355],
  [983, 6860, 2801, 3443],
  [6417, 7530, 1465, 8477],
  [5806, 799, 1931, 7805],
  [1307, 473, 5127, 6502],
  [7893, 3670, 1479, 7321],
  [7279, 35, 1812, 7713],
  [1627, 4984, 238, 7751],
  [6102, 3665, 7389, 1267],
  [1885, 6760, 8483, 828],
  [995, 1141, 6172, 8386],
  [4947, 8333, 3210, 7567],
  [3768, 7346, 3212, 5648],
  [1204, 6631, 3075, 4304],
  [4724, 7014, 8090, 2853],
  [8181, 7488, 3205, 4695],
  [3214, 6565, 116, 8628],
  [8521, 5069, 8168, 1333],
  [4311, 1376, 2158, 3660],
  [2859, 4471, 3040, 1051],
  [5755, 5264, 1319, 3278],
  [1774, 7955, 7372, 5559],
  [478, 6711, 6393, 5717],
  [7461, 322, 935, 2280],
  [2155, 6600, 5178, 5599],
  [2886, 3203, 1174, 6787],
  [6533, 5610, 3151, 2741],
  [5948, 7051, 8035, 287],
  [5671, 7517, 4532, 1129],
  [2383, 4607, 3925, 5718],
  [3626, 3791, 7827, 8690],
  [6447, 4099, 576, 3856],
  [2732, 8002, 2265, 4631],
]
export default {words}
