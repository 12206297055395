const words = [
  [7371, 3969],
  [1697, 6733],
  [3798, 5075],
  [1870, 2662],
  [5878, 675],
  [11, 2830],
  [2705, 6157],
  [8759, 2758],
  [4934, 3444],
  [2410, 7407],
  [3983, 1476],
  [3160, 6852],
  [2421, 2263],
  [1983, 3412],
  [7243, 3462],
  [1532, 2370],
  [2132, 445],
  [3265, 2708],
  [1666, 2138],
  [5462, 845],
  [846, 7398],
  [7793, 3677],
  [6030, 7157],
  [6416, 1918],
  [7249, 3255],
  [984, 2562],
  [2396, 994],
  [5497, 1315],
  [8238, 4691],
  [1670, 4854],
  [7325, 8177],
  [1489, 3262],
  [2648, 7054],
  [227, 3239],
  [2677, 3244],
  [669, 3183],
  [8237, 6062],
  [5378, 5751],
  [8704, 8357],
  [2798, 2330],
  [2586, 3189],
  [219, 4560],
  [222, 4342],
  [5594, 7038],
  [1004, 5641],
  [7258, 5831],
  [591, 8140],
  [1296, 5475],
  [6876, 6298],
  [6522, 825],
  [5070, 6418],
  [6370, 8372],
  [2672, 386],
  [1293, 4865],
  [6024, 2540],
  [607, 6768],
  [410, 5602],
  [4654, 6775],
  [1382, 7255],
  [1623, 5466],
  [7333, 1548],
  [5666, 2805],
  [8528, 6961],
  [2175, 6757],
  [4162, 5411],
  [1342, 7319],
  [7986, 6761],
  [7777, 1805],
  [5905, 2682],
  [6638, 5865],
  [1325, 7457],
  [4779, 8674],
  [6764, 903],
  [3065, 7393],
  [5374, 209],
  [1466, 4951],
  [2436, 480],
  [5541, 175],
  [1363, 6437],
  [6376, 5647],
  [373, 4347],
  [8778, 5773],
  [8382, 831],
  [344, 1455],
  [7676, 6845],
  [290, 7312],
  [555, 3763],
  [3177, 15],
  [6246, 3359],
  [7304, 273],
  [2815, 3113],
  [5967, 2566],
  [2679, 7164],
  [2049, 423],
  [8011, 3666],
  [1920, 7854],
  [6609, 2792],
  [8811, 4605],
  [1317, 3411],
  [8387, 8495],
  [1391, 5521],
  [2261, 840],
  [3748, 1111],
  [4755, 2812],
  [5312, 7838],
  [8523, 5522],
  [1337, 3201],
  [8476, 5685],
  [1544, 8589],
  [4402, 3540],
  [790, 4892],
  [3115, 4369],
  [2726, 526],
  [193, 7780],
  [4886, 5622],
  [1239, 4777],
  [7801, 1935],
  [1407, 4274],
  [7370, 2941],
  [1304, 5908],
  [4473, 662],
  [8038, 6997],
  [2618, 3570],
  [8215, 4808],
  [2791, 1930],
  [8684, 7060],
  [907, 8133],
  [3223, 6039],
  [1829, 963],
  [4333, 6368],
  [2339, 6644],
  [2713, 8400],
  [1450, 2514],
  [8420, 2720],
  [5722, 7755],
  [3523, 4160],
  [7953, 2508],
  [2399, 8021],
  [2762, 3648],
  [8263, 1444],
  [1682, 2975],
  [1624, 3288],
  [5629, 4206],
  [3108, 2882],
  [664, 6502],
  [8172, 2721],
  [7933, 7529],
  [3219, 4944],
  [4203, 7413],
  [2775, 1934],
  [1672, 2372],
  [2085, 4656],
  [2115, 7322],
  [7085, 3460],
  [8179, 413],
  [853, 7798],
  [8340, 7065],
  [8501, 80],
  [5776, 1472],
  [4943, 3289],
  [6853, 3785],
  [7112, 5754],
  [140, 7069],
  [1386, 1050],
  [6477, 7089],
  [7388, 8099],
  [8258, 7401],
  [6675, 7754],
  [8404, 1416],
  [8241, 2146],
  [6636, 7975],
  [1213, 6344],
  [7339, 123],
  [5138, 379],
  [8259, 7361],
  [7533, 3647],
  [2601, 7229],
  [3747, 4431],
  [2444, 7146],
  [7266, 3269],
  [8473, 6962],
  [7324, 137],
  [3489, 3316],
  [1189, 690],
  [2159, 8242],
  [7424, 4744],
  [8609, 476],
  [6178, 6822],
  [4527, 804],
  [6301, 7937],
  [3627, 7757],
  [4981, 225],
  [32, 8091],
  [3739, 3218],
  [2643, 6012],
  [1658, 1938],
  [7408, 5976],
  [1725, 7078],
  [3741, 65],
  [8574, 5862],
  [7057, 5143],
  [196, 3862],
  [7969, 5126],
  [7931, 1667],
  [783, 2288],
  [8170, 7784],
  [8260, 7802],
  [2414, 1238],
  [4529, 2704],
  [6078, 2401],
  [8452, 1483],
  [2850, 3625],
  [5797, 8534],
  [7960, 1881],
  [794, 6320],
  [7954, 7522],
  [2455, 6256],
  [8565, 7810],
  [5759, 6741],
  [4805, 6635],
  [8406, 4637],
  [7007, 4851],
  [288, 1812],
  [5105, 6391],
  [483, 1009],
  [7270, 2854],
  [5134, 4629],
  [1451, 5327],
  [977, 2534],
  [2727, 7082],
  [4323, 4633],
  [8284, 7476],
  [7029, 1380],
  [6984, 5055],
  [5885, 1434],
  [2026, 2311],
  [6349, 4491],
  [1944, 1699],
  [1633, 2855],
  [4406, 4936],
  [4285, 8368],
  [7209, 1346],
  [924, 7343],
  [477, 8670],
  [3624, 2886],
  [4784, 2493],
  [3766, 7284],
  [5835, 4058],
  [1394, 826],
  [6588, 3645],
  [7948, 6028],
  [1229, 8068],
  [226, 7847],
  [4170, 3696],
  [6994, 448],
  [7961, 3886],
  [763, 395],
  [7794, 7321],
  [7426, 2063],
  [4561, 8307],
  [1045, 3251],
  [2305, 4942],
  [3070, 6022],
  [4251, 3529],
  [1673, 6754],
  [4263, 4663],
  [7348, 6428],
  [5193, 1005],
  [3414, 7525],
  [6516, 6421],
  [1910, 8004],
  [4199, 1704],
  [58, 4416],
  [2734, 8701],
  [4218, 4820],
  [468, 8650],
  [3213, 7652],
  [4872, 3033],
  [8342, 1003],
  [815, 3140],
  [5713, 6054],
  [1661, 21],
  [341, 8370],
  [7431, 6021],
  [1796, 8102],
  [7152, 5895],
  [2276, 6893],
  [4496, 1688],
  [6884, 3917],
  [593, 4068],
  [5283, 7274],
  [7063, 1631],
  [659, 4512],
  [8339, 6458],
  [8692, 7008],
  [4539, 698],
  [8271, 3890],
  [2321, 3167],
  [7390, 5341],
  [2270, 4085],
  [1622, 3386],
  [943, 5046],
  [8550, 1225],
  [7002, 5703],
  [5827, 3264],
  [7974, 1372],
  [7318, 5884],
  [643, 1445],
  [2150, 618],
  [1370, 4228],
  [5579, 610],
  [269, 5508],
  [1480, 8376],
  [1095, 6315],
  [3443, 2504],
  [3241, 7412],
  [2608, 7399],
  [8375, 74],
  [2739, 8235],
  [4460, 3234],
  [5899, 3421],
  [6155, 6652],
  [822, 1182],
  [6268, 6766],
  [3580, 1235],
  [135, 6915],
  [960, 5819],
  [2839, 7391],
  [7629, 3012],
  [962, 2441],
  [7543, 3664],
  [4869, 1339],
  [2845, 1995],
  [6785, 7936],
  [4420, 7328],
  [436, 3923],
  [4585, 1931],
  [512, 1316],
  [6530, 2740],
  [6751, 2972],
  [4697, 3734],
  [6282, 1554],
  [6233, 1820],
  [2340, 8354],
  [7282, 1998],
  [2711, 1711],
  [7539, 3688],
  [6782, 5418],
  [6769, 1710],
  [6227, 4645],
  [540, 7386],
  [7422, 4238],
  [4089, 4591],
  [8680, 3200],
  [1309, 6104],
  [2843, 2017],
  [4268, 8095],
  [6791, 4672],
  [1593, 2337],
  [7287, 4714],
  [2530, 3693],
  [1620, 6811],
  [975, 7647],
  [2195, 1785],
  [5219, 1132],
  [1190, 7186],
  [295, 5648],
  [6565, 3778],
  [3399, 84],
  [1676, 6052],
  [1362, 4212],
  [1121, 6633],
  [8057, 1819],
  [5324, 5712],
  [7117, 8700],
  [7746, 6471],
  [7043, 5518],
  [3553, 5961],
  [6989, 6324],
  [1685, 3119],
  [6160, 7195],
  [3433, 291],
  [2282, 1021],
  [2449, 8682],
  [4708, 8353],
  [7003, 4859],
  [828, 245],
  [5843, 3007],
  [5282, 6903],
  [7275, 8807],
  [7140, 1220],
  [2665, 7050],
  [5257, 8554],
  [7310, 6041],
  [7340, 6356],
  [3274, 7517],
  [5872, 3844],
  [6365, 8201],
  [199, 7637],
  [973, 7234],
]
export default {words}
